import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query Resources {
        materials: allFile(filter: {relativeDirectory: {eq: "downloads/material"}}) {
            edges {
                node {
                    name
                    publicURL
                    ext
                    size
                }
            }
        }
        guides: allFile(filter: {relativeDirectory: {eq: "downloads/guides"}}) {
            edges {
                node {
                    name
                    publicURL
                    ext
                    size
                }
            }
        }
    }
`


class Resources extends React.Component {
    render() {
        const materials = this.props.data.materials.edges;
        const guides = this.props.data.guides.edges;

        return (
            <Layout
                title="Resources"
                location={this.props.location}
            >
                <h3>Links</h3>
                <p>A collection of links for gardens, gardening, wildlife and local interests:</p>
                <ul>
                    <li><a href="https://www.dalgetybayandhillend.org/dalgety-bay-1">Dalgety Bay Community Woodlands Group</a></li>
                    <li><a href="https://dalgetycommunitytrust.co.uk">Dalgety Community Trust</a></li>
                    <li><a href="https://www.rbge.org.uk">Royal Botanic Garden Edinburgh</a></li>
                    <li><a href="https://standrewsbotanic.org">St Andrews Botanic Garden</a></li>
                    <li><a href="https://www.beechgrove.co.uk">Beechgrove Garden</a></li>
                    <li><a href="https://thecaley.org.uk">Royal Caledonian Horticultural Society</a></li>
                    <li><a href="https://www.gardenorganic.org.uk">Garden Organic</a></li>
                    <li><a href="https://www.srgc.org.uk">Scottish Rock Garden Club</a></li>
                    <li><a href="https://www.scottishgardenersforum.org.uk">Scottish Gardeners Forum</a></li>
                    <li><a href="https://scotlandsgardens.org">Scotland's Garden Scheme</a></li>
                    <li><a href="http://www.letsgogardening.co.uk">Let's Go Gardening</a></li>
                    <li><a href="https://www.rhs.org.uk">Royal Horticultural Society</a></li>
                    <li><a href="https://scottishwildlifetrust.org.uk">Scottish Wildlife Trust</a></li>
                    <li><a href="https://www.nts.org.uk">National Trust for Scotland</a></li>
                    <li><a href="https://www.nvsuk.org.uk">National Vegetable Society</a></li>
                    <li><a href="https://www.rspb.org.uk">RSPB</a></li>
                    <li><a href="https://www.camboestate.com">Cambo Gardens, Kingsbarns</a></li>
                    <li><a href="https://delphinium-society.co.uk">The Delphinium Society</a></li>
                    <li><a href="http://www.fifefuchsiasociety.org.uk">Fife Fuchsia Society</a></li>
                    <li><a href="http://www.eosbegonias.f9.co.uk">East of Scotland Begonia Society</a></li>
                    <li><a href="https://www.fifedirect.org.uk/login/index.cfm">Fife Direct</a></li>
                    <li><a href="https://www.fifetoday.co.uk">Fife Today</a></li>
                </ul>

                <h3 id="downloads">Society materials</h3>
                <ul>
                    {materials.map(({ node }) => {
                        return (
                            <li key={node.name}>
                                <Link to={node.publicURL}>
                                    {node.name}{node.ext}
                                </Link>
                                {' '}[{(node.size / 1024).toFixed(2)} kb]
                            </li>
                        )
                    })}
                </ul>
                <h3>Guides</h3>
                <ul>
                    {guides.map(({ node }) => {
                        return (
                            <li key={node.name}>
                                <Link to={node.publicURL}>
                                    {node.name}{node.ext}
                                </Link>
                                {' '}[{(node.size / 1024).toFixed(2)} kb]
                            </li>
                        )
                    })}
                </ul>
            </Layout>
        )
    }
}
        
export default Resources